<template>
  <CommonLayout>
    <div class="scroll">
      <div
        v-for="(item, index) in ['未结算', '已结算']"
        :key="index"
        :class="settlement === index + 1 ? 'date' : ''"
        @click="getDataWithSettlement(index + 1)"
      >
        {{ item }}
      </div>
    </div>
    <div v-if="data.length > 0">
      <div class="main">
        <!-- 投注列表渲染 -->
        <div
          class="card"
          v-for="(item, index) in data"
          :key="index"
          style="background: white; padding: 0 10px"
        >
          <div class="title">
            <div>
              <p style="font-size: 16px; white-space: nowrap">
                {{ item.created_at.substring(5, 10) }}
              </p>
              <img
                v-if="item.created_at.substring(0, 10) == today"
                class="today"
                height="20px"
                src="@/assets/images/today.png"
                alt=""
              />
            </div>
            <p
              v-if="item.confirm_at && item.is_send != 2"
              style="font-size: 14px; display: flex"
            >
              <span style="color: #e96346">修改结果</span>
              (<van-count-down
                @finish="
                  () => {
                    getDataWithSettlement(1);
                  }
                "
                :time="item.confirm_at"
                format=" mm : ss "
                @change="item.confirm_at > 0 ? '' : (item.is_send = 2)"
              />)
            </p>
            <div v-else>
              <p v-if="item.status == 2" style="font-size: 12px">
                奖池总积分：
                {{
                  item.subject_option[0].integrate +
                  item.subject_option[1].integrate
                }}积分
                <span
                  v-if="item.is_send == 1"
                  style="margin-left: 5px; color: #e96346"
                  @click="revoke(item)"
                  >撤回</span
                >
              </p>
              <p v-else-if="item.status == 3"></p>
              <p v-else style="font-size: 12px">
                投注结束时间 : {{ item.ent_time }}
                <span
                  v-if="item.is_send == 1"
                  style="margin-left: 5px; color: #e96346"
                  @click="revoke(item)"
                  >撤回</span
                >
              </p>
            </div>
          </div>

          <div class="main_section">
            <div>
              <p style="text-align: left; line-height: 20px; font-size: 14px">
                题目:{{ item.name }}
              </p>
            </div>
            <div v-if="item.is_send == 1 && item.status == 1">
              <div
                class="main_dis"
                style="margin-top: 10px"
                v-for="(it, ind) in item.subject_option"
                :key="it.id"
              >
                <p
                  class="max_width"
                  :style="it.content.length > 12 ? 'font-size:12px' : ''"
                >
                  {{ ind + 1 }}、{{ it.content }}
                </p>
                <p class="odds">赔率： {{ it.the_odds }}</p>
              </div>
            </div>
            <div v-if="item.status == 3">
              <div
                class="main_dis"
                style="margin-top: 10px"
                v-for="(it, ind) in item.subject_option"
                :key="it.id"
              >
                <p
                  class="max_width"
                  :style="it.content.length > 12 ? 'font-size:12px' : ''"
                >
                  {{ ind + 1 }}、{{ it.content }}
                </p>
                <p class="odds">赔率： {{ it.the_odds }}</p>
              </div>
            </div>
            <div v-else-if="item.is_send == 1 && item.status == 2">
              <div
                v-for="(it, ind) in item.subject_option"
                :key="it.id"
                class="sure_flex"
              >
                <img
                  v-if="it.id == item.subject_option_id"
                  class="verss"
                  width="20px"
                  height="20px"
                  src="@/assets/seal/true.png"
                  alt=""
                />
                <img
                  @click="item.subject_option_id = it.id"
                  v-else
                  class="verss"
                  width="20px"
                  height="20px"
                  src="@/assets/seal/false.png"
                  alt=""
                />
                <div
                  @click="item.subject_option_id = it.id"
                  class="main_dis"
                  :style="
                    `margin-top: 10px;width:85%;` +
                    (it.id == item.subject_option_id ? 'color:#E96346' : '')
                  "
                >
                  <p
                    class="max_width"
                    :style="it.content.length > 12 ? 'font-size:12px' : ''"
                  >
                    {{ ind + 1 }}、{{ it.content }}
                  </p>
                  <p class="odds">赔率： {{ it.the_odds }}</p>
                </div>
              </div>
            </div>
            <div v-else-if="item.is_send == 2">
              <div
                v-for="(it, ind) in item.subject_option"
                :key="it.id"
                class="sure_flex"
              >
                <img
                  v-if="it.id == item.subject_option_id"
                  class="verss"
                  width="20px"
                  height="20px"
                  src="@/assets/seal/disable.png"
                  alt=""
                />
                <img
                  v-else
                  class="verss"
                  width="20px"
                  height="20px"
                  src="@/assets/seal/false.png"
                  alt=""
                />
                <div
                  class="main_dis"
                  :style="
                    `margin-top: 10px;width:85%;` +
                    (it.id == item.subject_option_id ? 'color:#999' : '')
                  "
                >
                  <p
                    class="max_width"
                    :style="it.content.length > 12 ? 'font-size:12px' : ''"
                  >
                    {{ ind + 1 }}、{{ it.content }}
                  </p>
                  <p class="odds">赔率： {{ it.the_odds }}</p>
                </div>
              </div>
            </div>
            <button
              class="bet"
              v-if="item.status == 2 && item.is_send == 1"
              @click="confirm(item)"
            >
              {{ item.confirm_at ? "修改" : "确认" }}胜方
            </button>
            <button
              class="bet"
              v-else-if="item.status == 1"
              @click="disable(item)"
            >
              封盘
            </button>
            <button
              class="bet"
              style="background: #d0d7e6"
              disabled
              v-else-if="item.status == 3"
            >
              已撤销
            </button>
            <button
              class="bet"
              style="background: #d0d7e6"
              disabled
              v-else-if="item.status == 2 && item.is_send == 2"
            >
              {{
                item.subject_option_id == item.subject_option[0].id
                  ? item.subject_option[0].content
                  : item.subject_option[1].content
              }}队伍 胜利
            </button>
          </div>
        </div>
      </div>
    </div>
    <van-empty
      style="margin-top: 150px"
      v-else
      :image="require('@/assets/images/empty.png')"
      description="暂无数据"
    />
  </CommonLayout>
</template>

<script>
import Vue from "vue";
import { Icon, Dialog, Notify, Empty, CountDown } from "vant";
import CommonLayout from "@/layouts/CommonLayout.vue";

Vue.use(CountDown);
Vue.use(Icon);
Vue.use(Empty);
export default {
  components: {
    CommonLayout,
  },
  data() {
    return {
      data: "", //数据
      today: "", //今天日期
      time: 10 * 60 * 1000,
      settlement: 1,
    };
  },
  methods: {
    // 撤销题目
    revoke(data) {
      Dialog.confirm({
        message: `确定撤回该竞猜？取消后竞猜币将全部返还给参与投注的竞猜者。`,
      })
        .then(async () => {
          const res = await this.$api.Record.revoke({
            id: data.id,
          });
          if (res.errorCode == 1000) {
            Notify({ type: "success", message: res.errorMessage });
            this.getDataWithSettlement(1);
          } else {
            Notify({ type: "danger", message: res.errorMessage });
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    //确认获胜队伍
    confirm(data) {
      let name = "";
      data.subject_option.forEach((item) => {
        if (item.id == data.subject_option_id) {
          name = item.content;
        }
      });
      Dialog.confirm({
        message: `确认将奖励发放给竞猜${name}队伍胜利的用户吗？`,
      })
        .then(async () => {
          const res = await this.$api.Record.confirm({
            id: data.id,
            subject_option_id: data.subject_option_id,
          });
          if (res.errorCode == 1000) {
            Notify({ type: "success", message: res.errorMessage });
            this.getDataWithSettlement(1);
          } else {
            Notify({ type: "danger", message: res.errorMessage });
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    //获取今天日期
    getToday() {
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      if (dd < 10) {
        dd = "0" + dd;
      }
      _this.gettime = yy + "-" + mm + "-" + dd;
      this.today = _this.gettime;
    },
    //手动封盘
    disable(data) {
      Dialog.confirm({
        message: `确认将该题目封盘吗？`,
      })
        .then(async () => {
          const res = await this.$api.Record.disable({
            id: data.id,
          });
          if (res.errorCode == 1000) {
            Notify({ type: "success", message: res.errorMessage });
            this.getDataWithSettlement(1);
          } else {
            Notify({ type: "danger", message: res.errorMessage });
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    getDataWithSettlement(id) {
      this.settlement = id;
      this.$api.Record.mylist({
        is_settlement: id,
      }).then((res) => {
        //获取当前时间
        var _this = this;
        let yy = new Date().getFullYear();
        let mm = new Date().getMonth() + 1;
        let dd = new Date().getDate();
        let hh = new Date().getHours();
        let min = new Date().getMinutes();
        let ss = new Date().getSeconds();
        if (dd < 10) {
          dd = "0" + dd;
        }
        _this.gettime =
          yy + "-" + mm + "-" + dd + " " + hh + ":" + min + ":" + ss;
        var gettimeA = _this.gettime.replace(new RegExp("-", "gm"), "/");
        var gettimeM = new Date(gettimeA).getTime(); //得到当前时间毫秒数
        if (res.errorCode == 1000) {
          this.data = res.data;
          // res.data.confirm_at     修改的时间
          this.data.forEach((item) => {
            if (item.confirm_at && item.is_send != 2) {
              var starttimeA = item.confirm_at.replace(
                new RegExp("-", "gm"),
                "/"
              );
              var starttimeM = new Date(starttimeA).getTime(); //得到题目修改时间毫秒数
              if (600000 - (gettimeM - starttimeM) > 0) {
                item.confirm_at = 600000 - (gettimeM - starttimeM);
              }
            }
          });
        }
      });
    },
  },
  created() {
    this.getDataWithSettlement(1);
    this.getToday();
  },
};
</script>

<style lang="less" scoped>
.sure_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header {
  height: 40px;
  width: 100%;
  line-height: 40px;
  background: #f8f8f8;
  text-align: center;
  font-size: 16px;
  position: fixed;
  z-index: 1000;
  top: 0;
}
.icon {
  font-size: 18px;
  font-weight: bold;
  position: fixed;
  top: 12px;
  left: 10px;
  z-index: 50000;
}
.main {
  width: 100%;
  padding: 0 9px;
  box-sizing: border-box;
  margin-bottom: 25px;
}
.title {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  div {
    display: flex;
    align-items: center;
  }
  .today {
    line-height: 20px;
    color: white;
    border-radius: 7px 0 7px 0;
    background: #79cdf4;
    margin-left: 5px;
    text-align: center;
    transform: scale(0.8);
    font-size: 12px;
  }
}

.main_section {
  width: 100%;
  padding: 10px;
  // border-radius: 10px;
  // border: 1px solid #ddd;
  box-sizing: border-box;
  font-size: 14px;
  p {
    margin: 0;
    margin-bottom: 10px;
  }
  .main_dis {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #f8f9fc;
    margin-bottom: 5px;
    align-items: center;
    padding: 10px 5px 0px 5px;
    box-sizing: border-box;

    .max_width {
      width: 170px;
      text-align: left;
      word-break: break-all;
    }
    .odds {
      color: #999;
    }
  }
  .bet {
    margin: 0;
    height: 44px;
    background: #e96346;
    border: none;
    width: 90%;
    color: white;
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 16px;

    .icon {
      vertical-align: text-top;
      font-size: 16px;
    }
  }
}

.scroll {
  margin-top: 12px;
  width: 100%;
  display: flex;
  // justify-content: space-around;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  // background: red;
  margin-left: 5%;
  height: 40px;
  &::-webkit-scrollbar {
    display: none;
  }
  div {
    margin-right: 24px;
    line-height: 40px;
  }
  .date {
    border-bottom: 2px solid #e96346;
    font-weight: bold;
  }
}
</style>
